import React, { useState, useEffect } from "react";

import { useLocation } from "@reach/router";
import Head from "../../components/Head/Head";
import Header from "../../components/Header/Header";
import FreeFooter from "../../components/common/Footer/FreeFooter/FreeFooter";
import EstimateSection from "../../components/sections/YourEstimate/YourEstimate";
import Videos from "../../components/sections/Videos/Videos";
import Questions from "../../components/sections/Questions/Questions";
import ForumApi from "../../procedures/forum";
import { Spinner } from "react-bootstrap";
import PRICING_ACTIONS from "../../redux/actions/Pricing";
import { useDispatch } from "react-redux";

const Marketing = () => {
  const location = useLocation();
  const [description, setDescription] = useState("");
  const [loadingMedia, setLoadingMedia] = useState(false);
  const [modules, setModules] = useState([]);
  const [content, setContent] = useState([]);
  const [fid, setFid] = useState("");
  const [comments, setComments] = useState([]);
  const [commentsLoading, setCommentsLoading] = useState(false);
  const [title, setTitle] = useState("");
  const dispatch = useDispatch();
  const getForumData = async (page) => {
    try {
      setLoadingMedia(true);
      const res = await ForumApi.getForumData(page);
      setContent(res?.pageMedia?.assets);
      setDescription(res?.pageMedia?.description);
      setTitle(res?.pageMedia?.title);
      setFid(res?.fid);
      setComments(res?.comments);

      setModules(res?.modules);
      setLoadingMedia(false);
      const action = PRICING_ACTIONS.setForumId(res?.fid);
      dispatch(action);
    } catch (error) {
      setLoadingMedia(false);
      setContent([]);
    }
  };
  useEffect(() => {
    getForumData("marketing");
    document.documentElement.style.overflow = 'visible';
  }, []);
  return (
    <>
      {loadingMedia ? (
        <div className="page-spinner">
          <Spinner animation="border" role="status" color="white" />
        </div>
      ) : (
        <>
          <Head />
          <Header pageId={location?.pathname} />
          <div className="content-holder mt-35">
            <EstimateSection
              modules={modules}
              showBanner
              title={title}
              description={description}
              showSidePanel
              page="product-marketing"
            />
            <Videos
              media={content}
              description={description}
              page="product-marketing"
            />
            {fid && (
              <Questions
                page="product-marketing"
                pageFid={fid}
                comments={comments}
                setComments={setComments}
                commentsLoading={commentsLoading}
                setCommentsLoading={setCommentsLoading}
              />
            )}
          </div>
          <FreeFooter />
        </>
      )}
    </>
  );
};

export default Marketing;
